import React from "react"
import Layout from "../components/layout"

import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

export default function Home() {
  return (
    <Layout>
      <div className="text-center my-10 w-11/12 sm:w-4/5 lg:w-3/5 m-auto">
        <h2 className="text-xl mb-2">Liebe Gäste,</h2>
        <p>Unsere Aussengastronomie ist geöffnet und beheizt!</p>
        <p>
          <strong>Täglich von 11:30 - 14:30 und 17:30 - 23:00</strong>
        </p>
        <p>
          <strong>Mittags gibt es wechselnde Menus</strong>
        </p>
        <p>
          <strong>Dienstag ist Ruhetag</strong>
        </p>
        {/* <p className="mt-5">
          Corona Schnell-Test steht vor Ort kostenlos zur Verfügung. Wir
          übernehmen die Kosten.
        </p> */}
        <p>
          Bitte bei Abholung, Vorbestellung oder Tischreservierung die{" "}
          <strong>02621/50606</strong> anrufen.
        </p>
        <p className="mt-5">
          <strong>Essen To-Go:</strong> Sie können Ihre Speisen auch weiterhin
          gerne per Telefon bestellen & Sie anschließend bei uns abholen!
        </p>
        {/* <p>
          <strong>Lieferservice</strong> ab 25,- Euro Mindestbestellwert frei
          Haus in Lahnstein
        </p> */}

        <p className="mt-5">Vielen Dank,</p>
        <p>Ihr Delphi Lahnstein</p>
      </div>
    </Layout>
  )
}
